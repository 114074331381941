import React from "react";
import OrganicLinkHero from "../components/Services/OrganicLinkHero";
// import PackageSection from "../components/Services/PackageSection";
import Banner from "../components/Home/Banner";
import KeyAreasOragnicLink from "../components/Services/KeyAreasOragnicLink";
import OrganicLinkPackage from "../components/Services/OrganicLinkPackage";
// import KeyAreasLinkInsertion from "../components/Services/KeyAreasLinkInsertion";

const OrganicLinkPage = () => {
  return (
    <main className=" flex flex-col gap-20 lg:gap-40 text-lg-start text-center text-wrap ">
      <div className="pt-40">
      <div className="custom-padding">
         <OrganicLinkHero />
      </div>
       
      </div>
      <section className="custom-bg-shape">
         <div className="custom-padding">
        <KeyAreasOragnicLink />
      </div>
      </section>
     
      <div className="custom-padding">
        <Banner />
      </div>
      <div className="custom-padding">
        <OrganicLinkPackage />
      </div>
    </main>
  );
};

export default OrganicLinkPage;
