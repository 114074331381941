import React from "react";
import { CgOrganisation } from "react-icons/cg";
import { BsGraphUpArrow } from "react-icons/bs";
import { RiContractLine } from "react-icons/ri";

const OurStory = () => {
  return (
    <div className=" font-montserrat text-start ">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div data-aos="fade-up">
          <div className="flex gap-4 items-center text-2xl font-semibold mb-4">
            {" "}
            <span className="text-orange-500 lg:text-4xl">
              <CgOrganisation />
            </span>
            <h2 className="">Our Story</h2>
          </div>

          <p className="mb-4">
            Based in Durgapur, India, Black Bee Digital Marketing Agency was
            established in 2020 after being inspired by a passion for creativity
            and knowledge of the internet. The Idea our founders, Kalyan
            Chattaraj & Sumana K Chattaraj among others, had about the company
            was creating a business offering innovative marketing services while
            enjoying long-term partnerships with its clients. The company was
            initially founded with a few key employees to stand as a globally
            representative agency.
          </p>
        </div>
        <div data-aos="fade-up">
          <div className="flex gap-4 items-center text-2xl font-semibold mb-4">
            {" "}
            <span className="text-orange-500 lg:text-4xl">
              <BsGraphUpArrow />
            </span>
            <h2 className="">Our Mission</h2>
          </div>
          <p className="mb-4">
            Black Bee Digital is ready to provide you with the most reliable
            digital marketing solutions for your business. We strive to provide
            outstanding, professional services that enhance our clients' goals
            and objectives.
          </p>
        </div>
      </div>
      <div className="mt-8" data-aos="fade-up">
        <div className="flex gap-4 items-center text-2xl font-semibold mb-4">
          {" "}
          <span className="text-orange-500 lg:text-4xl">
            <RiContractLine />
          </span>
          <h2 className="">Our Values</h2>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <ul className="list-disc pl-5 space-y-2 text-start">
            <li>
              <strong>Integrity:</strong> We also hold the principles of being
              truthful and having different and correct business practices in
              high regard.
            </li>
            <li>
              <strong>Excellence:</strong> Quality is an important aspect of any
              organization, making us stick to a policy of offering the best
              quality and results.
            </li>
            <li>
              <strong>Customer-Centric:</strong> Our customers are at the center
              of our operations, making it very important to put their best
              interests first. Our service is flexible because we provide
              custom-designed solutions to suit your goals and requirements.
            </li>
          </ul>
          <ul className="list-disc pl-5 space-y-2">
            <li>
              <strong>Innovation:</strong> We are constantly looking for trends
              and innovations in digital marketing to reach the target audience.
            </li>
            <li>
              <strong>Collaboration:</strong> It is also important to maintain
              proper customer relations so that the agreement between the firm
              and the clients is not seen as adversarial but a win-win
              situation.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default OurStory;
