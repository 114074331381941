import React from "react";
import BlogHero from "../components/Blogs/BlogHero";
import BlogMain from "../components/Blogs/BlogMain";

const BlogsPage = () => {
  return (
    <main className="custom-padding flex flex-col gap-20 lg:gap-40  text-lg-start text-center text-wrap ">
      <div className="pt-40">
        <BlogHero />
      </div>

      <div>
        <BlogMain />
      </div>
    </main>
  );
};

export default BlogsPage;
