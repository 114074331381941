import animesh from "../assets/About/Team/b417d-animesh.jpg";
import sucheta from "../assets/About/Team/48524-sucheta.jpg";
import kalyan from "../assets/About/Team/238ec-kalyan-1.jpg";
import sumana from "../assets/About/Team/8f163-sumana-1.jpg";
import kaushik from "../assets/About/Team/08e07-kaushik.jpg";
import anup from "../assets/About/Team/anup.jpg";
import dummy from "../assets/About/Team/54f99-girl-avatar-png-image-transparent.png";
import sudip from "../assets/About/Team/Sudip.jpeg";

export const ourTeam = [
  {
    img: kalyan,
    name: "Kalyan Chattaraj",
    role: "Founder and CEO",
    linkedIn: "https://www.linkedin.com/in/kalyan-chattaraj-1935a61a3",
  },
  {
    img: sumana,
    name: "Sumana Chattaraj",
    role: "Co-Founder",
    linkedIn: "https://www.linkedin.com/in/dgpseo",
  },
  {
    img: animesh,
    name: "Animesh Bannerjee",
    role: "Tech Support and Blogger ",
    linkedIn: "https://www.linkedin.com/in/animesh-banerjee-3b49a61b1",
  },
  {
    img: dummy,
    name: "Pranati Chakroborty",
    role: "Link building Expert ",
    linkedIn: "https://www.linkedin.com/in/pranati-chakraborty-pal-b288b0210",
  },
  {
    img: sucheta,
    name: "Sucheta Adhikary",
    role: "Digital Marketing Specialist",
    linkedIn: "https://www.linkedin.com/in/sucheta-adhikary-84607a202/",
  },

  {
    img: kaushik,
    name: "Kaushik Paul",
    role: "Digital Marketing Expert",
    linkedIn: "https://www.linkedin.com/in/kaushik-paul-b8ba1b1a9",
  },
  {
    img: sudip,
    name: "Sudip Banerjee",
    role: "Outreach Expert",
    linkedIn: "https://www.linkedin.com/in/sudip-banerjee-bb53ab211/",
  },
  {
    img: anup,
    name: "Anup K Jana",
    role: "Software Developer",
    linkedIn: "https://linkedin.com/in/anupkumarjana",
  },
];
