import React from "react";
import { ContentMarketingData } from "../../utils/ContentMarketingData";
import ContentMarketingCard from "./ContentMarketingCard";

const OrganicLinkHero = () => {
  return (
    <div className="flex flex-col gap-20">
      {/* <div className="mb-10">
        <span className="text-sm font-montserrat font-medium">
          HOME/SERVICES/
          <span className="text-orange-500">CONTENT MARKETING</span>
        </span>
      </div> */}
      <h2
        className="text-lg lg:text-2xl text-orange-500 font-montserrat font-semibold "
        data-aos="fade-down"
      >
        ORGANIC LINK BUILDING
      </h2>
      {/* -----------------------content section----------------------------- */}
      <div className="flex lg:flex-row flex-col lg:justify-between lg:items-start items-center gap-10">
        {/* ------------------------left section----------------------- */}

        <div className="lg:w-1/2 w-full flex flex-col gap-20 lg:justify-start justify-center">
          <div className="flex flex-col gap-4 lg:pr-8">
            <h4 className="text-4xl font-bold font-montserrat uppercase">
              We help brands grow their business with high-quality SEO link
              building services.
            </h4>
            <p
              className="text-lg font-normal font-montserrat"
              data-aos="fade-up"
            >
              Our expert writers craft original, well-researched, and insightful
              articles, providing the best guest posting service available.
              These unique articles include links to your business and are
              posted on targeted sites. If you’re looking to buy blog posts or
              explore guest blogging services, our premium guest posting
              services can significantly boost your website traffic. With access
              to over 12000 websites worldwide, you can easily choose high DA
              (Domain Authority) websites for submitting guest posts through our
              services.
            </p>
            <p
              className="text-lg font-normal font-montserrat "
              data-aos="fade-up"
            >
              This ensures you receive valuable do-follow backlinks from
              relevant, high-authority sites, making our guest post service a
              top choice for businesses seeking to enhance their online
              presence.
            </p>
          </div>
        </div>
        {/* ------------------------right section----------------------- */}
        <div
          className="lg:w-1/2  w-full flex flex-wrap gap-10"
          data-aos="fade-up"
        >
          {ContentMarketingData.map((data, index) => (
            <div key={index} className="lg:w-60 w-full">
              <ContentMarketingCard data={data} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OrganicLinkHero;
