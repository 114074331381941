import React from "react";
import Hero from "../components/Home/Hero";
import BuisnessGrowth from "../components/Home/BuisnessGrowth";
import Banner from "../components/Home/Banner";
import Offer from "../components/Home/Offer";
import Testimonials from "../components/Home/Testimonials";

const HomePage = () => {
  return (
    <main className="flex-col gap-20 text-lg-start text-center text-wrap pt-40">
      <div className=" lg:mb-0 mb-20 ">
        <Hero />
      </div>
      <div className="flex flex-col gap-40">
      <section className="custom-bg-shape">
         <div className="custom-padding">
          <BuisnessGrowth />
        </div>
      </section>
       
        <div className="custom-padding">
          <Banner />
        </div>
        <section className="custom-bg-shape">
          <div className=" custom-padding">
          <Offer />
        </div>
        </section>
        
        <div className="">
          <Testimonials />
        </div>
      </div>
    </main>
  );
};

export default HomePage;
