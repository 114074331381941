import React from "react";
import MediaBuyingHero from "../components/Services/LinkedInsertionHero";
import LinkInsertionPackage from "../components/Services/LinkInsertionPackage";
import Banner from "../components/Home/Banner";
import KeyAreasLinkInsertion from "../components/Services/KeyAreasLinkInsertion";

const MediaBuying = () => {
  return (
    <main className=" flex flex-col gap-20 lg:gap-40  text-lg-start text-center text-wrap ">
      <div className="pt-40">
        <div className="custom-padding">
          <MediaBuyingHero />
        </div>
      </div>
      <section className="custom-bg-shape">
        <div className="custom-padding">
          <KeyAreasLinkInsertion />
        </div>
      </section>

      <div className="custom-padding">
        <Banner />
      </div>
      <div className="custom-padding">
        <LinkInsertionPackage />
      </div>
    </main>
  );
};

export default MediaBuying;
