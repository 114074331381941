import React from 'react'

const KeyAreasContentMarketing = () => {
   const keyAreas = [
     {
       title: "Premium Quality Content",
       description:
         "Our in-house team of skilled content writers is dedicated to meeting your custom requirements for guest post writing. Adhering to high writing standards, our writers ensure each article is 100% unique and meticulously crafted by hand.",
     },
     {
       title: "Customized Content Strategies",
       description:
         "We develop tailored content strategies aligned with your business goals and target audience. Our approach focuses on understanding your unique needs to create content that resonates and drives meaningful engagement.",
     },
     {
       title: "High-Quality Content Creation",
       description:
         "Our skilled team of content writers crafts premium, original content that adheres to industry standards and best practices. Each piece is meticulously researched, ensuring it meets your specifications and resonates with your audience.",
     },
     {
       title: "Comprehensive Distribution and Optimization",
       description:
         "We leverage effective distribution channels and optimize content for maximum reach and impact. From SEO optimization to strategic promotion across relevant platforms, we ensure your content reaches the right audience at the right time.",
     },
   ];

  return (
    <div className="container lg:p-6 font-montserrat text-center ">
      <h3 className="text-lg text-orange-500 mb-16" data-aos="fade-up">
        Our Key Areas
      </h3>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 ">
        {keyAreas.map((area, index) => (
          <div
            key={index}
            className="shadow-md rounded-lg p-6 border text-start text-balance w-full bg-[#002244] text-white"
          >
            <h3 className="text-xl font-semibold mb-4 text-orange-500">
              {area.title}
            </h3>
            <p>{area.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default KeyAreasContentMarketing