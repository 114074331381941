import React from "react";

const ServiceCard = ({ data }) => {
  return (
    <div className="w-full h-96 flex flex-col gap-6 bg-[#002244] text-white lg:p-6 p-3 rounded-xl outline outline-2 border-black border-2">
      <div className="flex flex-wrap justify-start items-center gap-4">
        <span className="lg:text-6xl text-3xl text-orange-500">
          {data.icon}
        </span>
        <span className="lg:text-2xl text-xl font-semibold font-montserrat text-orange-500">
          {data.title}
        </span>
      </div>
      <p className="lg:text-lg text-sm font-montserrat pb-4 pl-3 text-balance">{data.content}</p>
    </div>
  );
};

export default ServiceCard;
