import React from 'react'
import ContactHero from '../components/Contact/ContactHero';
import ContactForm from '../components/Contact/ContactForm';

const ContactPage = () => {
  return (
    <main className="custom-padding flex flex-col gap-20 lg:gap-40  text-lg-start text-center text-wrap ">
      <div className="pt-40">
        <ContactHero />
      </div>
      <div>
        <ContactForm />
      </div>
    </main>
  );
}

export default ContactPage