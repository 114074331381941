import React from "react";
import ContentMarketingHero from "../components/Services/ContentMarketingHero";
import ContentMarketingPackage from "../components/Services/ContentMarketingPackage";
import Banner from "../components/Home/Banner";
import KeyAreasContentMarketing from "../components/Services/KeyAreasContentMarketing";
// import KeyAreas from "../components/Services/KeyAreas";

const ContentMarketingPage = () => {
  return (
    <main className="flex flex-col gap-20 lg:gap-40 text-lg-start text-center text-wrap ">
      <div className="pt-40">
        <div className="custom-padding ">
          <ContentMarketingHero />
        </div>
      </div>
      <section className="custom-bg-shape">
        <div className="custom-padding ">
          <KeyAreasContentMarketing />
        </div>
      </section>

      <div className="custom-padding ">
        <Banner />
        <x></x>
      </div>

      <div className="custom-padding ">
        <ContentMarketingPackage />
      </div>
    </main>
  );
};

export default ContentMarketingPage;
