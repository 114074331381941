import React from "react";

const ShimmerEffect = () => {
  return (
    <div className="animate-pulse flex flex-col gap-20 pt-40 font-montserrat">
      <div className="custom-padding">
        <div className="row flex align-items-start">
          <div className="p-6 flex-1">
            <article className="flex flex-col gap-4">
              <div className="article-img w-full h-48 bg-gray-200 rounded"></div>
              <div className="flex flex-col gap-4">
                <div className="w-1/3 h-6 bg-gray-200 rounded"></div>
                <div className="media flex gap-4 items-start">
                  <div className="media-body flex flex-col">
                    <div className="w-2/3 h-8 bg-gray-200 rounded mb-2"></div>
                    <div className="flex gap-1 text-start">
                      <div className="w-12 h-4 bg-gray-200 rounded"></div>
                      <div className="w-2 h-2 bg-gray-200 rounded-full"></div>
                      <div className="w-16 h-4 bg-gray-200 rounded"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="article-content w-full h-24 bg-gray-200 rounded"></div>
            </article>
          </div>

          <div className="p-6 flex-shrink-0 lg:w-1/3">
            <div className="widget widget-latest-post">
              <div className="w-2/3 h-6 bg-gray-200 rounded mb-10"></div>
              <div className="flex flex-col gap-4">
                {Array(3)
                  .fill()
                  .map((_, index) => (
                    <div
                      className="latest-post-aside media d-flex text-start justify-content-between align-top"
                      key={index}
                    >
                      <div className="flex flex-row gap-4 items-start">
                        <div className="w-20 h-20 bg-gray-200 rounded"></div>
                        <div className="flex flex-col justify-between">
                          <div className="w-32 h-6 bg-gray-200 rounded mb-2"></div>
                          <div className="flex gap-1 text-start">
                            <div className="w-12 h-4 bg-gray-200 rounded"></div>
                            <div className="w-2 h-2 bg-gray-200 rounded-full"></div>
                            <div className="w-16 h-4 bg-gray-200 rounded"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                <div className="text-center">
                  <div className="w-24 h-10 bg-gray-200 rounded mt-4"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShimmerEffect;

export const BlogShimmer = () => {
  return (
    <div className="custom-padding">
      {/* Shimmer for blog post */}
      <div className="mb-8">
        <ShimmerEffect />
      </div>
      <div className="mb-8">
        <ShimmerEffect />
      </div>
      <div className="mb-8">
        <ShimmerEffect />
      </div>
    </div>
  );
};

// export default BlogShimmer;
