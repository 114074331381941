import React from "react";
import AboutHero from "../components/About/AboutHero";
import AboutCard from "../components/About/AboutCard";
import PartnerScroll from "../components/About/PartnerScroll";
import OurTeam from "../components/About/OurTeam";
import OurStory from "../components/About/OurStory";

const AboutPage = () => {
  return (
    <div className="  flex flex-col gap-20 lg:gap-40  text-lg-start text-center text-wrap ">
      <div className="pt-40 ">
        <div className="custom-padding">
          <AboutHero />
        </div>
      </div>
      <section className="custom-bg-shape">
        <div className="custom-padding">
          <OurStory />
        </div>
      </section>
      <div className="custom-padding">
        <AboutCard />
      </div>
      <div className="custom-padding">
        <PartnerScroll />
      </div>
      <section className="custom-bg-shape">
         <div className="custom-padding">
        <OurTeam />
      </div>
      </section>
     
    </div>
  );
};

export default AboutPage;
