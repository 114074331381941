import { BsRocket } from "react-icons/bs";
import { TbBadgeFilled } from "react-icons/tb";

export const packageData = [
  {
    planTitle: " DA 40+",
    price: "$499",
    icon: <BsRocket />,
    features: [
      "10 DA 40+ Guest Posts",
      "Real Organic Keyboard Websites",
      "Max 2 Do-Follow Links are allowed",
      "Normal Delivery Time 1 Week",
      "Only Niche Related Post Publish",
      "Google Index Included",
      "Sensitive contents are not allowed",
      "No Content Writing Fees Included",
    ],
  },
  {
    planTitle: " DA 50+",
    price: "$699",
    icon: <TbBadgeFilled />,
    features: [
      "10 DA 50+ Guest Posts",
      "Real Organic Keyboard Websites",
      "Max 2 Do-Follow Links are allowed",
      "Normal Delivery Time 1 Week",
      "Only Niche Related Post Publish",
      "Included Google Index",
      "Sensitive contents are not allowed",
      "No Content Writing Fees Included",
    ],
  },
  {
    planTitle: "DA 60+",
    price: "$999",
    icon: <BsRocket />,
    features: [
      "10 DA 60+ Guest Posts",
      "Real Organic Keyboard Websites",
      "Max 2 Do-Follow Links are allowed",
      "Normal Delivery Time 1 Week",
      "Only Niche Related Post Publish",
      "Included Google Index",
      "Sensitive contents are allowed",
      "No Content Writing Fees Included",
    ],
  },
];

// --------------------------------------------------------------------------------------


export const contentWritingPlans=[
    {
      "planTitle": "CW1 500+",
      "price": "$99",
      "features": [
        "You will get 5 articles of 500+ word count.",
        "All articles are guaranteed to be 100% original and unique",
        "Diverse Topic Covered.",
        "SEO Friendly Content.",
        "Experienced writers with expertise in content creation.",
        "Ensure prompt delivery of your content.",
        "Enjoy editing services free of charge.",
        "Competitive pricing without compromising on quality.",
        "Each article comes with a proof of plagiarism-free content"
      ]
    },
    {
      "planTitle": "CW2 500+",
      "price": "$199",
      "features": [
        "You will get 10 articles of 500+ word count.",
        "All articles are guaranteed to be 100% original and unique",
        "Diverse Topic Covered.",
        "SEO Friendly Content.",
        "Experienced writers with expertise in content creation.",
        "Ensure prompt delivery of your content.",
        "Enjoy editing services free of charge.",
        "Competitive pricing without compromising on quality.",
        "Each article comes with a proof of plagiarism-free content"
      ]
    },
    {
      "planTitle": "WC3 1000+",
      "price": "$399",
      "features": [
        "You will get 10 articles of 1000+ word count.",
        "All articles are guaranteed to be 100% original and unique",
        "Diverse Topic Covered.",
        "SEO Friendly Content.",
        "Experienced writers with expertise in content creation.",
        "Ensure prompt delivery of your content.",
        "Enjoy editing services free of charge.",
        "Competitive pricing without compromising on quality.",
        "Each article comes with a proof of plagiarism-free content"
      ]
    }
  ]

  // -----------------------------------------------------------------------------

  export const linkInsertionPlan = [
    {
      planTitle: "DA 40+",
      price: "$555",
      features: [
        "DA 40+ (10 Websites)",
        "Niche Edit With guaranteed Google indexing.",
        "1 Do-Follow Link Get Per Post",
        "Normal Delivery Time 1 Weeks",
        "Without Adult Other All Niche Link Allowed",
        "10 NICHE EDIT INCLUDED",
      ],
    },
    {
      planTitle: "DA 50+",
      price: "$777",
      features: [
        "DA 50+ (10 Websites)",
        "Niche Edit With guaranteed Google indexing.",
        "1 Do-Follow Link Get Per Post",
        "Normal Delivery Time 1 Weeks",
        "Without Adult Other All Niche Link Allowed",
        "10 NICHE EDIT INCLUDED",
      ],
    },
    {
      planTitle: "DA 60+",
      price: "$1111",
      features: [
        "DA 60+ (10 Websites)",
        "Niche Edit With guaranteed Google indexing.",
        "1 Do-Follow Link Get Per Post",
        "Normal Delivery Time 1 Weeks",
        "Without Adult Other All Niche Link Allowed",
        "10 NICHE EDIT INCLUDED",
      ],
    },
  ];
