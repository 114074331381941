import React from "react";
import { ContentMarketingData } from "../../utils/ContentMarketingData";
import ContentMarketingCard from "./ContentMarketingCard";

const ContentMarketingHero = () => {
  return (
    <div className="flex flex-col gap-20">
      {/* <div className="mb-10">
        <span className="text-sm font-montserrat font-medium">
          HOME/SERVICES/
          <span className="text-orange-500">CONTENT MARKETING</span>
        </span>
      </div> */}
      <h2
        className="text-lg lg:text-2xl text-orange-500 font-montserrat font-semibold "
        data-aos="fade-down"
      >
        CONTENT WRITING
      </h2>
      {/* -----------------------content section----------------------------- */}
      <div className="flex lg:flex-row flex-col lg:justify-between lg:items-start items-center gap-10">
        {/* ------------------------left section----------------------- */}

        <div className="lg:w-1/2 w-full flex flex-col gap-20 lg:justify-start justify-center">
          <div className="flex flex-col gap-4 lg:pr-8">
            {/* <h4 className="text-4xl font-bold font-montserrat">
              WE OFFER SEARCH ENGINE OPTIMIZED, QUALITY CONTENT WRITING
              SERVICES.
            </h4> */}
            <p
              className="text-lg font-normal font-montserrat"
              data-aos="fade-up"
            >
              Content marketing service involves creating and distributing
              valuable, relevant, and consistent content to attract and engage a
              specific target audience, ultimately driving profitable customer
              actions.
            </p>
            <p
              className="text-lg font-normal font-montserrat "
              data-aos="fade-up"
            >
              Effective content marketing builds brand awareness, establishes
              authority, drives website traffic, and nurtures customer
              relationships, ultimately leading to increased conversions and
              business growth.
            </p>
          </div>
        </div>
        {/* ------------------------right section----------------------- */}
        <div
          className="lg:w-1/2  w-full flex flex-wrap gap-10"
          data-aos="fade-up"
        >
          {ContentMarketingData.map((data, index) => (
            <div key={index} className="lg:w-60 w-full">
              <ContentMarketingCard data={data} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ContentMarketingHero;
