import React from "react";
import { ContentMarketingData } from "../../utils/ContentMarketingData";
import MediaBuyingCard from "./LinkedInsertionCard";

const MediaBuyingHero = () => {
  return (
    <div className="flex flex-col gap-20">
      {/* <div className="mb-10">
        <span className="text-sm font-montserrat font-medium">
          HOME/SERVICES/
          <span className="text-orange-500">MEDIA BUYING</span>
        </span>
      </div> */}
      <h2
        className="text-lg lg:text-2xl text-orange-500 font-montserrat font-semibold "
        data-aos="fade-down"
      >
        LINK INSERTION
      </h2>
      {/* -----------------------content section----------------------------- */}
      <div className="flex lg:flex-row flex-col lg:justify-between lg:items-start items-center gap-10">
        {/* ------------------------left section----------------------- */}

        <div className="lg:w-1/2 w-full flex flex-col gap-20 lg:justify-start justify-center">
          <div className="flex flex-col gap-4 lg:pr-8">
            {/* <h4 className="text-4xl font-bold font-montserrat">
              WE OFFER SEARCH ENGINE OPTIMIZED, QUALITY CONTENT WRITING
              SERVICES.
            </h4> */}
            <p
              className="text-lg font-normal font-montserrat"
              data-aos="fade-up"
            >
              Link insertion, also known as niche edit service, involves
              strategically adding links to existing content on authoritative
              websites within relevant niches. This method enhances SEO by
              leveraging established content and domain authority to improve
              search engine rankings and drive organic traffic. Choose us for
              our proven expertise in link insertion/niche edit services,
              commitment to quality and compliance, and dedication to maximizing
              your website's SEO potential through strategic link placements on
              authoritative websites.
            </p>
            <p
              className="text-lg font-normal font-montserrat "
              data-aos="fade-up"
            >
              Choose us for our proven expertise in link insertion/niche edit
              services, commitment to quality and compliance, and dedication to
              maximizing your website's SEO potential through strategic link
              placements on authoritative websites.
            </p>
          </div>
        </div>
        {/* ------------------------right section----------------------- */}
        <div
          className="lg:w-1/2  w-full flex flex-wrap gap-10"
          data-aos="fade-up"
        >
          {ContentMarketingData.map((data, index) => (
            <div key={index} className="lg:w-60 w-full">
              <MediaBuyingCard data={data} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MediaBuyingHero;
