import { TiSocialInstagram } from "react-icons/ti";
import { PiPackageFill } from "react-icons/pi";
import { MdWeb } from "react-icons/md";

export const ContentMarketingData = [
  {
    icon: <MdWeb />,
    title: "High Authority & Quality Sites",
    // description: "Lorem ipsum dolor",
  },
  {
    icon: <TiSocialInstagram />,
    title: "Relevant Niche Links",
    // description: "Lorem ipsum dolor",
  },
  {
    icon: <PiPackageFill />,
    title: "Choose Websites by Filters",
    // description: "Lorem ipsum dolor",
  },
  {
    icon: <MdWeb />,
    title: "Result Oriented Output",
    // description: "Lorem ipsum dolor",
  },
];
