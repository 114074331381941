// themes.js
export const lightTheme = {
  body: "#FFF",
  text: "#363537",
  toggleBorder: "#FFF",
  background: "#363537",
  section: "#F5F5DC",
  sectionText: "#363537", // Black text for light theme
};

export const darkTheme = {
  body: "#363537",
  text: "#FAFAFA",
  toggleBorder: "#6B8096",
  background: "#999",
  section: "#28282B",
  sectionText: "#FAFAFA", // White text for dark theme
};
