import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CardBody, CardTitle } from "reactstrap";
import { collection, getDocs, getFirestore } from "firebase/firestore";
import { app } from "../../FirebaseConfig";
import ShimmerBlogSection from "../Shimmer/ShimmerBlogSection";

const BlogMain = () => {
  const [blogData, setBlogData] = useState(null);

  useEffect(() => {
    const fetchBlogData = async () => {
      const db = getFirestore(app);
      const querySnapshot = await getDocs(collection(db, "posts"));
      const blogs = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setBlogData(blogs);
    };

    fetchBlogData();
  }, []);

  return (
    <>
      <h2
        className="text-lg lg:text-3xl text-orange-500 font-montserrat font-semibold mb-20"
        data-aos="fade-down"
      >
        OUR LATEST BLOGS
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        {blogData ? (
          blogData.map((data) => (
            <Link to={`/blogs/${data.id}`} key={data.id}>
              <div className="flex flex-col gap-4 w-full h-full border rounded-xl shadow-xl hover:scale-95 transition-all ease-in-out duration-200">
                {data.imageUrl && (
                  <img
                    src={data.imageUrl}
                    alt={data.title}
                    className="w-full h-40 object-cover rounded-t-xl"
                  />
                )}
                <CardBody className="pt-0 text-start p-2">
                  <CardTitle tag="h5" className="font-bold font-montserrat">
                    {data.title}
                  </CardTitle>
                  <div className="d-flex justify-content-between flex-wrap mb-2 pt-0">
                    <span>
                      <small>{data.date}</small>
                    </span>
                    <span>
                      <small>{data.categoryName}</small>
                    </span>
                  </div>
                </CardBody>
              </div>
            </Link>
          ))
        ) : (
          <ShimmerBlogSection />
        )}
      </div>
    </>
  );
};

export default BlogMain;
