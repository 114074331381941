import img from "../assets/Home/person-1.jpg";

export const testimonials = [
  {
    name: "STEPHANIE HOFHENKE",
    image: img,
    content:
      "Our website's traffic has skyrocketed since partnering with BLACK BEE. Their expert strategies and consistent support have made a tangible difference in our online visibility. Highly recommended!",
    organisation: "LSEO",
  },
  {
    name: "BARRY",
    image: img,
    content:
      " Working with BLACK BEE has been a game-changer for our business. Their tailored approach and deep understanding of SEO helped us climb to the top of search results, driving more qualified leads than ever before.",
    organisation: "Owner of Motorhead Digital",
  },
  {
    name: "TOM HENRY",
    image: img,
    content:
      " The team at BLACK BEE is phenomenal! They took the time to understand our goals and delivered impressive results. Our organic search traffic has improved significantly, boosting our overall sales.",
    organisation: "Global Head",
  },
  {
    name: "Jack Harrison",
    image: img,
    content:
      " I've worked with several SEO firms, but none compare to BLACK BEE. Their innovative techniques and commitment to excellence have transformed our online presence. We're seeing consistent growth month after month.",
    organisation: "Zolo Marketing",
  },
  {
    name: "Tarun Bhatia",
    image: img,
    content:
      " Choosing BLACK BEE was the best decision for our digital marketing strategy. Their expertise and proactive communication set them apart, and we've seen a remarkable improvement in our search rankings and website traffic.",
    organisation: "Aksh Media",
  },
  
];
