import React from "react";
import hero from "../../assets/Home/hero2.png";
import underline from "../../assets/Home/shape-03.svg";
import arrowImage from "../../assets/Home/sip-b-arrow.decb954d.svg";
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <div className="lg:h-screen relative custom-padding ">
      <img
        src={arrowImage}
        alt=""
        className="absolute top-[-100] left-0 opacity-20 lg:block hidden"
        data-aos="fade-right"
      />
      <div className="flex flex-col lg:flex-row items-center justify-center lg:justify-between lg:gap20 gap-12">
        {/* Text Content */}
        <div className="lg:w-1/2 w-full flex flex-col gap-10  lg:items-start items-center">
          <div className="relative">
            <h1
              className="lg:text-[3.5rem] text-3xl font-bold lg:leading-[3.6rem] leading-[2rem] font-montserrat"
              id="location-text"
              data-aos="fade-up"
            >
              We're <span className="text-[#FF4D30]">Digital Marketing</span>{" "}
              Creative Gigg!
            </h1>
            <img
              src={underline}
              alt=""
              className="absolute bottom-[-10] lg:block lg:left-0 left-[20%]"
              data-aos="fade-up"
            />
          </div>

          <p className="font-montserrat">Grow your buisness with us today!</p>
          <div className="flex gap-4 flex-wrap font-outfit" data-aos="fade-up">
            {/* <button className="border rounded-md px-4 py-2 bg-purple-600 text-white button">
              Find Solution
            </button> */}
            <Link to="/contact">
              {" "}
              <button className="lg:text-lg text-sm font-outfit font-normal flex items-center gap-2 rounded-xl lg:py-4 lg:px-8 px-4 py-2 bg-[#FF4D30] text-white shadow-custom hover:shadow-custom-hovered transition-all duration-300 ease-in-out  button">
                Find Solution
              </button>
            </Link>
            <Link to="/about">
              <button className="lg:text-lg text-sm font-outfit font-normal shadow-custom  border rounded-xl px-4 py-2 button hover:bg-black hover:text-white transition-all ease-in-out duration-200">
                Learn more
              </button>
            </Link>
          </div>
        </div>

        {/* Image */}
        <div
          className="lg:w-1/2 w-full flex justify-center lg:justify-end"
          data-aos="fade-down"
        >
          <img
            src={hero}
            alt="hero"
            className="hero-image w-full drop-shadow-2xl   "
          />
        </div>
      </div>
    </div>
  );
};

export default Hero;
