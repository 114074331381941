import React from 'react'

const KeyAreasLinkInsertion = () => {

    const keyAreas = [
      {
        title: "Extensive Network of High-Authority Websites",
        description:
          "We have access to a vast network of reputable websites across various niches. This allows us to secure placements on domains with strong domain authority (DA) and page authority (PA), enhancing the credibility and SEO impact of your links.",
      },
      {
        title: "Manual and Ethical Approach",
        description:
          "Our team adheres strictly to ethical SEO practices and manually edits content to seamlessly integrate links into existing articles. This ensures natural placement that aligns with the context of the content, maintaining authenticity and compliance with search engine guidelines.",
      },
      {
        title: "Customized Strategies",
        description:
          "We develop personalized link insertion strategies tailored to your specific goals and target audience. Whether you seek brand visibility, traffic generation, or SEO improvement, our strategies are designed to meet your objectives effectively.",
      },
      {
        title: "Transparent Reporting and Monitoring",
        description:
          "We provide detailed reports and ongoing monitoring of link placements and performance metrics. This transparency allows you to track the impact of our services on your SEO efforts and make informed decisions for future strategies.",
      },
    ];

  return (
    <div className="container mx-auto p-6 font-montserrat text-center">
      <h3 className="text-lg text-orange-500 mb-16" data-aos="fade-up">
        Our Key Areas
      </h3>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
        {keyAreas.map((area, index) => (
          <div
            key={index}
            className="shadow-md rounded-lg p-6 border text-start text-balance bg-[#002244] text-white"
          >
            <h3 className="text-xl font-semibold mb-4 text-orange-500">
              {area.title}
            </h3>
            <p>{area.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default KeyAreasLinkInsertion