import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import {
  getFirestore,
  doc,
  getDoc,
  getDocs,
  collection,
  query,
  orderBy,
  limit,
} from "firebase/firestore";
import { app } from "../FirebaseConfig";
import { LuDot } from "react-icons/lu";
import { BlogShimmer } from "../Shimmer";

const BlogSingle = () => {
  const { id } = useParams(); // Get the id parameter from the URL
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [latestPosts, setLatestPosts] = useState([]);

  useEffect(() => {
    const fetchBlog = async () => {
      const db = getFirestore(app);
      const blogRef = doc(db, "posts", id); // Reference to the blog document
      try {
        const blogDoc = await getDoc(blogRef);
        if (blogDoc.exists()) {
          setBlog({ id: blogDoc.id, ...blogDoc.data() }); // Set the blog data
        } else {
          setError("Blog not found");
        }
      } catch (error) {
        console.error("Error fetching blog:", error);
        setError("Error fetching blog");
      } finally {
        setLoading(false);
      }
    };

    fetchBlog();
  }, [id]);

  useEffect(() => {
    const fetchLatestPosts = async () => {
      const db = getFirestore(app);
      const latestPostsQuery = query(
        collection(db, "posts"),
        orderBy("date", "desc"),
        limit(4)
      );
      try {
        const querySnapshot = await getDocs(latestPostsQuery);
        const posts = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setLatestPosts(posts);
      } catch (error) {
        console.error("Error fetching latest posts:", error);
      }
    };

    fetchLatestPosts();
  }, []);

  if (loading) {
    return <BlogShimmer />;
  }

  if (error) {
    return <p>{error}</p>;
  }

  if (!blog) {
    return <p>Blog not found</p>;
  }

  return (
    <>
      {" "}
      {!blog ? (
        <BlogShimmer />
      ) : (
        <main className="flex flex-col gap-20 text-wrap font-montserrat pt-40">
          <div className="custom-padding">
            {/* <h2 className="mb-5">
            <span className="text-2xl font-bold font-outfit">{blog.title}</span>
          </h2> */}
            <div className="row align-items-start flex">
              <div className=" p-6 flex-1">
                <article className="flex flex-col gap-4">
                  <div className="article-img w-full">
                    {blog.imageUrl && (
                      <img
                        src={blog.imageUrl}
                        alt={blog.title}
                        className="object-cover w-full"
                      />
                    )}
                  </div>
                  <div className="flex flex-col gap-4">
                    <h6>
                      <a href="/blogs" className="text-orange-500 underline">
                        {blog.categoryName}
                      </a>
                    </h6>

                    <div className="media">
                      {/* <div className="avatar">
                <img src={blog.imageUrl} alt="" />
              </div> */}
                      <div className="media-body">
                        <h2 className="text-xl font-medium mb-2">
                          {blog.title}
                        </h2>
                        <div className="flex gap-1 text-start">
                          <span className="name text-xs text-orange-500 italic">
                            admin
                          </span>
                          <span>
                            <LuDot />
                          </span>
                          <span className="date text-xs">{blog.date}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="article-content"
                    dangerouslySetInnerHTML={{ __html: blog.description }}
                  />
                </article>
              </div>
              {/* ----------------------------Latest Posts------------------------------------- */}
              <div className="p-6 flex-shrink-0 lg:w-1/3">
                <div className="widget widget-latest-post">
                  <h3 className="text-orange-500 text-xl font-semibold mb-10">
                    Latest Posts
                  </h3>

                  <div className="flex flex-col gap-4">
                    {latestPosts.map((post) => (
                      <div
                        className="latest-post-aside media d-flex text-start justify-content-between align-top"
                        key={post.id}
                      >
                        <div className="flex flex-row gap-4 items-start">
                          <Link to={`/blogs/${post.id}`}>
                            <img
                              src={post.imageUrl}
                              title={post.title}
                              alt={post.title}
                              // width={100}
                              className="object-cover w-20"
                            />
                          </Link>

                          <div className="flex flex-col justify-between">
                            <h5 className="text-lg">
                              <Link to={`/blogs/${post.id}`}>{post.title}</Link>
                            </h5>

                            <div className="flex gap-1 text-start">
                              <span className="name text-xs text-orange-500 italic">
                                admin
                              </span>
                              <span>
                                <LuDot />
                              </span>
                              <span className="date text-xs">{post.date}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                    <div className="text-center">
                      <Link to="/blogs" className="btn btn-primary mt-4">
                        View All
                      </Link>
                    </div>
                  </div>
                </div>
                {/* End Latest Post */}
              </div>
            </div>
          </div>
        </main>
      )}
    </>
  );
};

export default BlogSingle;
