import { PiLinkSimpleFill } from "react-icons/pi";
import { BiSolidBookContent } from "react-icons/bi";
import { FaLaptopFile } from "react-icons/fa6";
import { MdCloudDone } from "react-icons/md";
import { SiWebpack } from "react-icons/si";
import { GiStack } from "react-icons/gi";

export const services = [
  {
    icon: <PiLinkSimpleFill />,
    title: "Link Building Service",
    content:
      "Link building is acknowledged as an important factor and key element of SEO management, designed to increase the website's rank. The backlinks are sourced through white-hat methods to get quality backlinks from the sites that Blackbee Digital partners with. ",
  },
  {
    icon: <BiSolidBookContent />,
    title: "Guest Posting Service",
    content:
      "Guest blogging is an essential SEO White Hat technique that Blackbee Digital provides to its clients, where the company writes quality content relevant to the client's niche and hosts it on powerhouse sites within the same industry. ",
  },
  {
    icon: <FaLaptopFile />,
    title: "Content Writing Service",
    content:
      "This is accurate as content is the most essential component of any digital marketing strategy. Blackbee Digital delivers professional content writing and ensures its services are customized to customers' needs. Employing the best and brightest in writing personnel, we specialize in creating blog posts, articles, and website content, among others, crafted to appeal to and relate to the target market and other marketing goals.",
  },
  {
    icon: <MdCloudDone />,
    title: "Link Insertion Service",
    content:
      "An important method used in information retrieval is link insertion, which entails the insertion of links into already existing content in other sites with high ranking. The link insertion from Blackbee Digital will complement this, placing links in the most important articles with high traffic rates for the client's benefit. ",
  },
  {
    icon: <SiWebpack />,
    title: "Web Design & Development",
    content:
      "The website design plays a role in enhancing its virtual identity. Blackbee Digital works closely with clients to build exceptional websites following the latest web design and development trends, ensuring that the developed websites match the client's brand image and are easy to navigate with good responsiveness. ",
  },
  {
    icon: <GiStack />,
    title: "Analytics & Reporting",
    content:
      "Many hypotheses and tests are involved in reaching customers; thus, it is crucial to incorporate analytics into any digital marketing efforts. With the help of Blackbee Digital, clients get detailed analytics of the marketing campaign they undertake, which helps to enhance the results.",
  },
];
