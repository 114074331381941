import React from "react";

const KeyAreasOragnicLink = () => {
  const keyAreas = [
    {
      title: "High Authority & Quality Sites",
      description:
        "We only post guest articles on sites with high authority and quality metrics. Our service does not include author bios or sponsored posts, ensuring the focus remains on valuable content and authentic backlinks.",
    },
    {
      title: "Relevant Niche Links",
      description:
        "A vast and continuously expanding database of niche websites to choose from.",
    },
    {
      title: "Choose Websites by Filters",
      description:
        "You can narrow down your website list using filters such as DA, PA, Alexa Rank, organic traffic, price, and more.",
    },
    {
      title: "Result Oriented Output",
      description:
        "Purchasing guest posts on high-traffic, high-quality websites will enhance your money-making website's organic visibility in search results.",
    },
  ];

  return (
    <div className="container mx-auto p-6 font-montserrat text-center">
      <h3 className="text-lg text-orange-500 mb-16" data-aos="fade-up">
        Our Key Areas
      </h3>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
        {keyAreas.map((area, index) => (
          <div
            key={index}
            className="shadow-md rounded-lg p-6 border text-start text-balance bg-[#002244] text-white"
          >
            <h3 className="text-xl font-semibold mb-4 text-orange-500">
              {area.title}
            </h3>
            <p>{area.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default KeyAreasOragnicLink;
