import React, { useEffect, useState } from "react";
import {
  getFirestore,
  collection,
  getDocs,
  deleteDoc,
  doc,
} from "firebase/firestore";

import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import { AdminHeader } from "../../components/AdminHeader";
// import { MdSkipPrevious } from "react-icons/md";

function ContactRequest() {
  const [contactRequests, setContactRequests] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Set the number of items per page
  const [expandedMessages, setExpandedMessages] = useState({});
  const db = getFirestore();

  useEffect(() => {
    const fetchContactRequests = async () => {
      try {
        const contactRequestsRef = collection(db, "contactForms");
        const contactRequestsSnapshot = await getDocs(contactRequestsRef);
        const contactRequestsList = contactRequestsSnapshot.docs.map((doc) => ({
          id: doc.id, // Include document ID
          ...doc.data(),
        }));
        setContactRequests(contactRequestsList);
      } catch (error) {
        console.error("Error fetching contact requests: ", error);
        toast.error("Error fetching contact requests");
      }
    };

    fetchContactRequests();
    //eslint-disable-next-line
  }, []);

  const handleDelete = async (id) => {
    if (!id) {
      console.error("Invalid document ID");
      toast.error("Invalid document ID");
      return;
    }
    try {
      await deleteDoc(doc(db, "contactForms", id));
      setContactRequests((prevRequests) =>
        prevRequests.filter((request) => request.id !== id)
      );
      toast.success("Deleted successfully");
    } catch (error) {
      console.error("Error deleting document: ", error);
      toast.error("Error while deleting");

      // Handle error
    }
  };

  // Calculate the total number of pages
  const totalPages = Math.ceil(contactRequests.length / itemsPerPage);

  // Get current items
  const currentItems = contactRequests.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const toggleMessage = (id) => {
    setExpandedMessages((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  return (
    <>
      <ToastContainer />
      <AdminHeader />
      <main className="px-20 pt-40 flex flex-col gap-10 lg:text-start text-center text-wrap">
        {/* <div className="text-center">
          <span
            className="lg:text-xl text-bold text-lg font-montserrat"
            data-aos="fade-up"
          >
            Contact Requests
          </span>
        </div> */}
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200 border border-gray-300 bg-none  text-black">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-2 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border border-gray-300">
                  Name
                </th>
                <th className="px-2 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border border-gray-300">
                  Email
                </th>
                <th className="px-2 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border border-gray-300">
                  Contact Number
                </th>
                <th className="px-2 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border border-gray-300">
                  Message
                </th>
                <th className="px-2 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border border-gray-300">
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200 text-sm text-center">
              {currentItems.map((request) => (
                <tr key={request.id} className="border border-gray-300">
                  <td className="px-2 py-1 whitespace-nowrap border border-gray-300">
                    {request.name}
                  </td>
                  <td className="px-2 py-1 whitespace-nowrap border border-gray-300">
                    {request.email}
                  </td>
                  <td className="px-2 py-1 whitespace-nowrap border border-gray-300">
                    {request.phone}
                  </td>
                  <td className="px-2 py-1 whitespace-nowrap border border-gray-300 max-w-xs">
                    {expandedMessages[request.id] ? (
                      <div className="whitespace-pre-wrap">
                        {request.message}
                        <button
                          className="text-blue-500 ml-2"
                          onClick={() => toggleMessage(request.id)}
                        >
                          See Less
                        </button>
                      </div>
                    ) : (
                      <div>
                        {request?.message?.length > 40
                          ? `${request?.message?.substring(0, 40)}...`
                          : request?.message}
                        {request?.message?.length > 40 && (
                          <button
                            className="text-blue-500 ml-2"
                            onClick={() => toggleMessage(request.id)}
                          >
                            See More
                          </button>
                        )}
                      </div>
                    )}
                  </td>
                  <td className="px-2 py-1 whitespace-nowrap border border-gray-300">
                    <button
                      className="bg-red-500 text-white px-2 py-1 text-sm rounded"
                      onClick={() => handleDelete(request.id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="flex justify-center gap-4 items-center mt-4">
            <button
              className="bg-gray-300  px-3 py-1 rounded disabled:opacity-50 cursor-pointer"
              onClick={handlePrevPage}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span className="text-sm ">
              Page {currentPage} of {totalPages}
            </span>
            <button
              className="bg-gray-300  px-3 py-1 rounded disabled:opacity-50 cursor-pointer"
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </div>
      </main>
    </>
  );
}

export default ContactRequest;
